import PropTypes from "prop-types";

// material-ui
import { Divider, Grid, IconButton, Typography } from "@mui/material";

// project imports
import moment from "moment";
import {
  IconBrandInstagram,
  IconBrandLinkedin,
  IconMailbox,
} from "@tabler/icons-react";
import { blue, blueGrey, orange, pink } from "@mui/material/colors";
import CustomLink from "components/Link";

// ==============================|| FOOTER ||============================== //

const Footer = () => {
  return (
    <Grid container style={{ margin: 0 }}>
      <Divider
        style={{
          width: "100%",
          borderBottomWidth: "medium",
          borderColor: blueGrey[300],
        }}
      />
      <Grid
        container
        style={{
          display: "flex",
          margin: 7.5,
        }}
      >
        <Grid item>
          <CustomLink dest="mailto:info@shivalicious.com" external={true}>
            <IconButton>
              <IconMailbox color={orange[500]} />
            </IconButton>
          </CustomLink>
          <CustomLink
            dest="https://www.linkedin.com/in/shiva-velingker/"
            external={true}
          >
            <IconButton>
              <IconBrandLinkedin color={blue[500]} />
            </IconButton>
          </CustomLink>
          <CustomLink
            dest="https://www.instagram.com/shivalicious/"
            external={true}
          >
            <IconButton>
              <IconBrandInstagram color={pink[500]} />
            </IconButton>
          </CustomLink>
        </Grid>
        <Grid item style={{ flex: 1, textAlign: "right" }}>
          <Typography>
            &#169; {moment().year()} by Shiva
            <br />
            Built meticulously by me {String.fromCodePoint("0x1F605")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

Footer.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Footer;
