import _ from "lodash";
import React from "react";
import { Box, List, ListItem } from "@mui/material";
import MenuButton from "./MenuButton";

const useRovingIndex = (options) => {
  const {
    initialActiveIndex = 0,
    vertical = false,
    handlers = {
      onKeyDown: () => {},
    },
  } = options || {};
  const [activeIndex, setActiveIndex] = React.useState(initialActiveIndex);
  const targetRefs = React.useRef([]);
  const targets = targetRefs.current;

  const focusNext = () => {
    let newIndex = activeIndex + 1;
    if (newIndex >= targets.length) {
      newIndex = 0;
    }
    targets[newIndex]?.focus();
    setActiveIndex(newIndex);
  };

  const focusPrevious = () => {
    let newIndex = activeIndex - 1;
    if (newIndex < 0) {
      newIndex = targets.length - 1;
    }
    targets[newIndex]?.focus();
    setActiveIndex(newIndex);
  };

  const getTargetProps = (index) => ({
    ref: (ref) => {
      if (ref) {
        targets[index] = ref;
      }
    },
    tabIndex: activeIndex === index ? 0 : -1,
    onKeyDown: (e) => {
      if (Number.isInteger(activeIndex)) {
        if (e.key === (vertical ? "ArrowDown" : "ArrowRight")) {
          focusNext();
        }
        if (e.key === (vertical ? "ArrowUp" : "ArrowLeft")) {
          focusPrevious();
        }
        handlers.onKeyDown?.(e, { setActiveIndex });
      }
    },
    onClick: () => {
      setActiveIndex(index);
    },
  });

  return {
    activeIndex,
    setActiveIndex,
    targets,
    getTargetProps,
    focusNext,
    focusPrevious,
  };
};

export default function NavigationMenu({ groups, sx }) {
  const { getTargetProps, focusNext, focusPrevious } = useRovingIndex();

  return (
    <Box sx={sx}>
      <List
        role="menubar"
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: 1,
          gap: 1,
        }}
      >
        {_.map(groups, (group, idx) => (
          group.visible === false ? null :
          <ListItem key={idx} role="none">
            <MenuButton
              title={group.name}
              target={group.target}
              links={group.children || []}
              focusNext={focusNext}
              focusPrevious={focusPrevious}
              targetProps={getTargetProps(idx)}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
